body{
  direction: rtl;
  font-family: 'Vazirmatn', sans-serif;
}

.main-wrapper{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: auto;
}

input[type="date"]::-webkit-datetime-edit-fields-indicator {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
}

/* select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: left 0.7rem top 50%;
  background-size: 0.65rem auto;
} */

svg {
  enable-background: new 0 0 1698 135;
  width: 100%;
  height: 100%;
}

.bg-header-wave{
  height: 115px;
}

.bg-header-footer-wave{
  height: 15%;
}

.item-nav-transition {
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  border: 1px solid #fff;
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(.68,-.55,191,149,73);
  -moz-transition: -moz-transform 0.4s cubic-bezier(.68,-.55,.265,1.55);
  transition: transform 0.4s cubic-bezier(.68,-.55,.265,1.55);
  -webkit-animation: ripple 0.7s linear infinite;
  animation: ripple 0.7s linear infinite;
}

@-webkit-keyframes ripple {
  0% {
      box-shadow: 0 0 0 0 rgba(191,149,73,.3),0 0 0 7px rgba(191,149,73,.3),0 0 0 14px rgba(191,149,73,.3),0 0 0 21px rgba(191,149,73,.3);
  }

  100% {
      box-shadow: 0 0 0 7px rgba(191,149,73,.3),0 0 0 14px rgba(191,149,73,.3),0 0 0 21px rgba(191,149,73,.3),0 0 0 28px rgba(191,149,73,0);
  }
}
@keyframes ripple {
  0% {
      box-shadow: 0 0 0 0 rgba(191,149,73,.3),0 0 0 7px rgba(191,149,73,.3),0 0 0 14px rgba(191,149,73,.3),0 0 0 21px rgba(191,149,73,.3);
  }

  100% {
      box-shadow: 0 0 0 7px rgba(191,149,73,.3),0 0 0 14px rgba(191,149,73,.3),0 0 0 21px rgba(191,149,73,.3),0 0 0 28px rgba(191,149,73,0);
  }
}
@keyframes rippleLeave {
  0% {
      box-shadow: 0 0 0 0 rgba(191,149,73,.3),0 0 0 7px rgba(191,149,73,.3),0 0 0 14px rgba(191,149,73,.3);
  }

  100% {
      box-shadow: 0 0 0 7px rgba(191,149,73,.3),0 0 0 14px rgba(191,149,73,.3),0 0 0 21px rgba(191,149,73,0);
  }
}


.select__control {
  border: none !important;
  outline: none !important;
  height: 2.5rem !important;
  z-index: 50 !important;
}


.react-datepicker {
  position: absolute !important;
  top: 10rem !important;
  z-index: 9999 !important;
}


.ticket-search header button {
  background-color: #FFF;
  padding: 0.5rem;
  color: #550200;
  text-align: center;
  width: 6rem;
  height: 3rem;
  transition: all 0.2s linear 0.2s;
}

.ticket-search header .active-btn {
  background-color: #550200;
  color: #FFF;
}

.ticket-search header button i {
  margin: auto 0.5rem;
}

.travel-explorer .travel-explorer-head button{

  padding: 0.25rem 1rem;
  margin: auto 0.25rem;
  border-radius: 1rem;
  background-color: #FFF;
  color: #550200;
}

.travel-explorer .travel-explorer-head .active{
  background-color: #550200;
  color: #FFF;
}
/* 
.jcontainer {
  --bg-offset: 66.66%;
  display: flex;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  background: white;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.jcontainer::after {
  content: "";
  width: 33.33%;
  height: 100%;
  background: #55020022;
  color: white;
  position: absolute;
  top: 0;
  left: var(--bg-offset);
  transition: left 0.3s;
  align-content: center;
}

.jcontainer > button {
  width: 150px;
  border: none;
  padding: 0.75rem 1.5rem;
  background: transparent;
  cursor: pointer;
}

.jcontainer > button:not(:last-of-type) {
  border-right: 1px solid #eee;
} */

.langSelect .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.head-select-lang {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.head-select-lang::after {
  content: "\25BC"; 
  font-size: 16px;
  color: #FFF;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;
}

.nav-drawer-btn {
  position: absolute !important;
  right: 0.25rem;
}

.nav-drawer-btn svg{
  width: 2.5rem !important;
  height: auto;
}
@media (min-width: 1280px) {
  .nav-drawer-btn {
      display: none !important;
  }
}

.MuiPickersLayout-actionBar div:first {
  display: none !important;
}

.MuiPickersLayout-root {
  direction: ltr !important;
}


.city-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.city-logo {
  width: 24px;
  height: 24px;
  margin-right: auto 10px;
}

/* .option--is-selected {
  width: 100px;
  background-color: #FFF;
} */

/* .selected-logo {
  width: 24px;
  height: 24px;
  margin-right: auto 10px;
} */

.MuiSelect-select {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
}

.MuiSelect-select img {
  width: 16px !important;
  height: 16px !important;
}

.MuiSvgIcon-root {
  color: white !important;
}

.user-tickes-table td {
  text-align: center;
  padding: 10px;
}

.user-tickes-table tbody td {
  border: 1px solid #EEE;
}



.ticket-items > * {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation: staggerIn 0.5s ease-in-out forwards;
}

@keyframes staggerIn {
  from { transform: translateY(100%); opacity: 0;}
  to { transform: translateY(0); opacity: 1;  }
}

.MuiDialog-container .MuiSvgIcon-root {
  color: #000 !important;
}

.vehicleBtnActive {
  background: #5501002e !important;
  height: 100% !important;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-16 17:59:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
